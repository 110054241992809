<!-- 优惠活动 -->
<template>
  <!-- <div class="page-promo page-elec">
        <div class="promo-img">
            <img loading="lazy" v-lazy="require('../../assets/images/index/banner.png')"> 
        </div>
        <div class="promo-content-box">
            <div class="promo-left">
                <div class="promo-left-item" @click="changeTab('')" :class="activeType ? '' : 'promo-left-item_active'">
                    <img v-if="activeType" src="../../assets/images/other/black_youhui_a001_icon01.png" alt="">
                    <img v-else src="../../assets/images/other/black_youhui_a001_icon01_i.png" alt="">
                    <div>{{$t("promoInfo.text1")}}</div>
                </div>
                <div class="promo-left-item" :class="activeType == item.id ? 'promo-left-item_active' : ''" 
                v-for="(item, i) in promoMenu" :key="i" @click="changeTab(item.id)" :title="item.remark">

                    <img v-if="activeType == item.id" src="../../assets/images/other/black_youhui_a001_icon01_i.png" alt="">
                    <img v-else src="../../assets/images/other/black_youhui_a001_icon01.png" alt="">
                    <div>{{ item.remark }}</div>
                </div>
            </div>
            <div class="promo-right">
                <div class="promo-right-item" v-for="(item, i) in list" :key="i" @click="promoInfo(item)">
                    <div :class="'promo-right-title' + i" class="promo-right-title">{{ item.forever == 1 ? $t("promoInfo.text2") : $t("promoInfo.text3") }}</div>
                    <img class="promo-right-item_img"  loading="lazy" v-lazy="item._picture"> 
                    <span class="item-name">{{ item.name }}</span>
                    <span class="item-time">{{ item._titleText }}</span>
                </div> 
            </div>
            
            <Empty v-if="!list.length" :description='$t("userPage.text54")' />
            <div v-else class="pagination-box">
                <Pagination
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="currPage"
                :page-size="pageSize"
                :total="total"
                @current-change="loadData"
                ></Pagination>
            </div>
        </div>
    </div> -->
  <div class="promo-box">
    <div class="function-page">
      <!-- <div class="info-type">
        <div
          v-for="(item, i) in promoMenu"
          :key="i"
          @click="changeTab(item.id)"
          :title="item.remark"
          class="info_Item txtBtn-2"
          :class="{ current: activeType == item.id }"
        >
          {{ item.remark }}
        </div>
      </div> -->
      <ul class="info-items clearfix">
        <li v-for="(item, i) in list" :key="i" class="clearfix">
          <div class="info-tit" style="">
            <img loading="lazy" v-lazy="item._picture" />
            <div class="info-desc">
              <div class="info-desc-bg">
                <p class="info-desc-title">
                  {{ item.name }}
                </p>
                <span>{{ item.intro }} </span>
                <!--<span>2022/04/22</span>-->
              </div>
            </div>
            <div @click="promoInfo(item)" class="more-btn">BIÊT NHIÊU HƠN</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { Pagination } from "element-ui";
import { mapGetters } from "vuex";
import { _activity } from "../../core/api/modules/promo";
import { _createQRcode, _downloadApp } from "../../core/utils/utils";
import { _activeInfoAction } from "../../core/comp/common";
// import { Empty } from "element-ui";
import { _getTime } from "../../core/utils/utils";
export default {
  name: "promo",
  components: {
    // Empty,
    // Pagination,
  },
  data() {
    return {
      curItem: {},
      list: [], // 活动列表
      activeType: "", // 当前选择的分类
      currPage: 1, // 当前第几页
      pageSize: 999, // 每页多少条
      total: 0,
      loading: false,
      moreShow: true,
      downloadUrl: "",
    };
  },
  computed: {
    ...mapGetters([
      // "promoMenu", // 分类列表
      "systemImgHost", // 图片服务器
    ]),
  },
  mounted() {
    this.loadData();
    this.qrcodeChange();
  },
  methods: {
    qrcodeChange() {
      this.downloadUrl = _downloadApp();
      _createQRcode({
        id: "qrcode",
        content: this.downloadUrl,
        width: 158,
        height: 158,
      });
    },
    moreChange() {
      this.moreShow = !this.moreShow;
    },
    // 获取活动分类
    // getPromoMenu() {
    //   if (this.promoMenu.length) return;
    //   _activityType();
    // },
    // 切换分类
    changeTab(id) {
      if (this.activeType == id) return;
      this.activeType = id;
      this.currPage = 1;
      this.list = [];
      this.lastPage = false;
      this.$nextTick(() => {
        this.loadData();
      });
    },

    // 加载数据
    loadData() {
      const params = {
        currentPage: this.currPage,
        pageSize: this.pageSize,
        ascriptionType: this.activeType,
      };
      this.loading = true;
      _activity(params).then((res) => {
        this.loading = false;
        if (!res || res.code) return;
        res.data.content.map((a) => {
          // 设置图片链接
          a._picture = this.systemImgHost + a.picture;
          // 设置时间文本
          a._titleText = "";
          if (a.forever == 1) a._titleText = _getTime(a.validStartTime);
          if (a.forever == 0 && a.type == 0)
            a._titleText = `${_getTime(a.validStartTime)}${this.$t(
              "promoInfo.text5"
            )}${_getTime(a.validEndTime)}`;
          if (a.forever == 0 && a.type != 0)
            a._titleText = `${_getTime(a.startTime)}${this.$t(
              "promoInfo.text5"
            )}${_getTime(a.endTime)}`;
          return a;
        });
        this.list = res.data.content;
        this.total = res.data.totalRecords;
      });
    },
    // 查看活动详情
    promoInfo(item) {
      _activeInfoAction(item);
    },
  },
};
</script>

<style lang="less" scoped>
.promo-box {
  position: relative;
  background-color: #333435;
  width: 100%;
  min-height: 100vh;
  padding: 0 0;
  margin: 0 0;
  .function-page {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 60px;
    position: relative;
    .info-type {
      margin-bottom: 23px;
      white-space: nowrap;
      overflow: auto hidden;
      width: 100%;
      scrollbar-width: none;
      display: flex;
      .info_Item {
        font-size: 16px;
        font-weight: 600;
        height: 41px;
        line-height: 41px;
        margin: 0 1% 0 0;
        padding: 0 15px;
        display: inline-block;
        box-sizing: border-box;
        border: 1px solid #00a6ff;
        border-radius: 23px;
        color: #00a6ff;
        cursor: pointer;
      }
      .current {
        background-color: #00a6ff;
        color: #fff;
      }
      .clearfix {
        width: 100%;
        display: inline-block;
      }
    }
    ul {
      list-style: none;
      padding: 0 0;
      margin: 0 0;
    }
    .info-items {
      width: 102%;
      margin: 0 -1%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 0;
      li {
        background-color: #2d2d2d;
        padding: 8px 8px;
        width: 31%;
        margin: 1%;
        border-radius: 5px;
        list-style-type: none;
        box-sizing: border-box;
        .info-tit {
          cursor: pointer;
          border-radius: 5px;
          padding-bottom: 2px;
          box-sizing: border-box;
          img {
            width: 100%;
            // height: 100%;
            max-width: 100%;
            height: 10vw;
            max-height: 206px;
            border-radius: 5px 5px 0 0;
            vertical-align: top;
            border: 0;
          }
          .info-desc {
            padding: 2px;
            background: #3a3a3b;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
            border-radius: 0 0 5px 5px;
            .info-desc-bg {
              width: 100%;
              padding: 5px 10px;
              color: #ce06cd;
              display: flex;
              flex-flow: column;
              text-align: center;
              box-sizing: border-box;
              p {
                font-size: 20px;
                font-weight: bold;
                line-height: 30px;
                height: 30px;
                overflow: hidden;
                padding: 0 0;
                margin: 0 0;
              }
              span {
                font-size: 14px;
                line-height: 30px;
                height: 30px;
                word-break: break-word;
                color: #fff;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          .more-btn {
            text-align: center;
            width: 50%;
            margin: 10px auto 0 auto;
            font-size: 16px;
            color: #fff;
            background: #ce06cd;
            height: 41px;
            line-height: 41px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
